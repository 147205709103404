import { Link } from "react-router-dom";
import "./PageNavigator.css";

function PageNavigator() {
  return (
    <header className="about-page-header2">
      <Link to="/" className="about-page-link invert-text">
        Shun Ueno
      </Link>
      <a
        href="mailto:shunueno@stanford.edu"
        className="nav-link work-link invert-text"
      >
        CONTACT
      </a>
    </header>
  );
}

export default PageNavigator;
