// src/HostileArchitecturePage.js
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import PageNavigator from "./components/PageNavigator";
import BackButton from "./components/BackButtonBlack";

import "./HostileArchitecturePage.css";
import Footer from "./components/Footer";
import ProjectNavigator from "./components/ProjectNavigator";

function HostileArchitecturePage() {
  const [showVideo, setShowVideo] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Animation variant for each group with blur effect
  const groupAnimation = {
    hidden: { opacity: 0, y: 0, filter: "blur(10px)" },
    visible: { opacity: 1, y: 0, filter: "blur(0px)" },
  };

  // Check if the screen width is mobile-sized on component mount
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="hostile-architecture-page">
      {!isMobile && <PageNavigator />}
      {isMobile && <BackButton />}
      <h1 className="hostile-architecture-title">
        Hostile Architecture and Inaccessibility
      </h1>
      <div className="media-container">
        {showVideo ? (
          <iframe
            src="https://www.youtube.com/embed/9CyK23N3yv4?si=FvAdT7jTlAUPNA5R"
            title="Hostile Architecture and Inaccessibility"
            className="work-video"
            allowFullScreen
          ></iframe>
        ) : (
          <img
            src="/pwr.png"
            alt="Hostile Architecture and Inaccessibility"
            className="work-imageh"
          />
        )}
        <button
          className="toggle-media-btn"
          onClick={() => setShowVideo(!showVideo)}
        >
          {showVideo ? "Show Image" : "Show Video"}
        </button>
      </div>

      <div className="hostile-architecture-content">
        <div className="project-overview-container">
          <h2>Project Overview</h2>
          <div className="project-overview-content">
            <div className="project-info-container">
              <div className="project-info">
                <div className="project-detail">
                  <span className="label">Duration:</span>
                  <span className="value">9 weeks</span>
                </div>
                <div className="project-detail">
                  <span className="label">Disciplines:</span>
                  <span className="value">
                    Accessible Design, UX Research
                  </span>
                </div>

                <div className="project-detail">
                  <span className="label">Recognition:</span>
                  <span className="value">2024 Lunsford Award Nominee</span>
                </div>
              </div>
            </div>

            <div className="project-summary">
              <p>
                This research paper critically examines the design and social
                impact of hostile architecture in Tokyo, focusing on unintended
                but significant consequences for people with disabilities.
                Through case studies on public seating, it explores how design
                choices aimed at deterring homelessness—such as angled surfaces
                and obstructive armrests—create physical barriers for those with
                mobility challenges. The paper combines personal experiences and
                academic research to spotlight the ethical implications of these
                architectural choices on public accessibility.
              </p>
            </div>
          </div>
        </div>
        <hr className="divider" />

        <motion.div
          className="project-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.1 }}
          transition={{ duration: 0.8 }}
          variants={groupAnimation}
        >
          <h2>Key Insights</h2>
          <div className="insights-container">
            <div className="insight-image-text">
              <div className="insight-image">
                <figure>
                  <img
                    src="/red_bench.jpeg"
                    alt="Hostile bench with unique design"
                  />
                  <figcaption>
                    Hostile bench design blending aesthetics and anti-homeless
                    features
                  </figcaption>
                </figure>
              </div>
              <div className="insight-text">
                <h3>Unique Characteristics of Tokyo’s Designs</h3>
                <p>
                  Unlike hostile designs in other cities, Tokyo’s “removal art”
                  approach subtly blends anti-homeless features into visually
                  appealing public furniture, making accessibility challenges
                  less visible and less discussed.
                </p>
              </div>
            </div>
            <div className="insight-image-text">
              <div className="insight-image">
                <figure>
                  <img src="/shibuya.png" alt="Circular bench in Shibuya" />
                  <figcaption>Circular bench in Shibuya</figcaption>
                </figure>
              </div>
              <div className="insight-text">
                <h3>Impact on Disabled Individuals</h3>
                <p>
                  The research highlights that features like angled seating and
                  circular benches prevent resting and actively exclude people
                  with disabilities, seniors, and those in need of accessible
                  public spaces.
                </p>

                <h3>Intersectional Consequences</h3>
                <p>
                  {" "}
                  Viewing hostile architecture through an intersectional lens,
                  the paper reveals the broader impact on various vulnerable
                  groups, including the elderly, people with disabilities, and
                  those experiencing homelessness.
                </p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className="project-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.17 }}
          transition={{ duration: 0.8 }}
          variants={groupAnimation}
        >
          <div className="highligh-learning-container">
            <div className="highlight-text">
              <h2>Research Highlights</h2>
              <ul>
                <li>
                  <strong>Personal Narrative & Visual Documentation</strong>:
                  The research is rooted in both personal experience and visual
                  evidence, using photographs and firsthand accounts to
                  illustrate how hostile architecture impacts everyday mobility
                  and public participation.
                </li>
                <li>
                  <strong>Comparative Analysis</strong>: By situating Tokyo’s
                  designs within a global context, this paper compares Tokyo’s
                  subtle hostile architecture with more overt implementations
                  elsewhere, raising critical questions about balancing urban
                  aesthetics with social inclusivity.
                </li>
                <li>
                  <strong>Social Advocacy</strong>: The research argues for
                  urban planning that prioritizes accessibility, inclusivity,
                  and equity in public spaces, challenging the exclusionary
                  nature of hostile design.
                </li>
              </ul>
            </div>
            <div className="highlight-text">
              <h2>Learnings</h2>
              <ul>
                <li>
                  <strong>
                    Enhanced Understanding of Ethical Urban Design
                  </strong>
                  : Gained a deeper awareness of the ethical responsibilities in
                  urban design, particularly in ensuring that public spaces are
                  inclusive and accessible to all, regardless of physical
                  ability. This project reinforced the importance of considering
                  unintended social impacts when designing for public use.
                </li>
                <li>
                  <strong>Intersectional Research Skills</strong>: Developed
                  skills in intersectional analysis, learning to assess design
                  impacts across multiple vulnerable groups, such as people with
                  disabilities, the elderly, and individuals experiencing
                  homelessness, to inform more inclusive urban planning.
                </li>
                <li>
                  <strong>
                    Effective Communication of Social Advocacy through Research
                  </strong>
                  : Learned to convey complex ethical and accessibility issues
                  in a clear, impactful way, advocating for social inclusivity
                  in public spaces through both academic writing and visual
                  documentation.
                </li>
              </ul>
            </div>
          </div>
          <hr className="divider" />
        </motion.div>

        
        <motion.div
          className="project-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.1 }}
          transition={{ duration: 0.8 }}
          variants={groupAnimation}
        >
          <h2>Research Paper</h2>
          <div className="embed-container">
        <iframe
          src="https://drive.google.com/file/d/1pAcudZ42SkV_HUgu7IYz-_9AQSPupr0d/preview"
          title="Hostile Architecture Document"
          className="embed-iframe"
          allowFullScreen
        ></iframe>
      </div>
            
          
        </motion.div>


      </div>
      
    
      <ProjectNavigator currentProject="Hostile Architecture and Inaccessibility" />
      <Footer />
    </div>
  );
}

export default HostileArchitecturePage;
