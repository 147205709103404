// src/EclipsePage.js
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./EclipsePage.css";
import PageNavigator from "./components/PageNavigator";
import BackButton from "./components/BackButtonBlack";
import Footer from "./components/Footer";
import ProjectNavigator from "./components/ProjectNavigator";

function EclipsePage() {
  const [isMobile, setIsMobile] = useState(false);
  // Animation variant for each group with blur effect

  const groupAnimation = {
    hidden: { opacity: 0, y: 0, filter: "blur(10px)" },
    visible: { opacity: 1, y: 0, filter: "blur(0px)" },
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="eclipse-page">
      {!isMobile && <PageNavigator />}
      {isMobile && <BackButton />}

      <h1 className="eclipse-title">ECLIPSE</h1>
      <div className="gif-container">
        <img
          src="/eclipse2.gif"
          alt="Eclipse Animation"
          className="eclipse-gif"
        />
      </div>

      <div className="eclipse-content">
        <div className="project-overview-container">
          <h2>Project Overview</h2>
          <div className="project-overview-content">
            <div className="project-info-container">
              <div className="project-info">
                <div className="project-detail">
                  <span className="label">Duration:</span>
                  <span className="value">10 weeks</span>
                </div>
                <div className="project-detail">
                  <span className="label">Skills:</span>
                  <span className="value">Figma, Illustrator, Photoshop</span>
                </div>
                <div className="project-detail">
                  <span className="label">Disciplines:</span>
                  <span className="value">Graphic Design, Poster Design</span>
                </div>
                <div className="project-detail">
                  <span className="label">Role:</span>
                  <span className="value">Graphic and Brand Designer</span>
                </div>
                <div className="project-detail">
                  <span className="label">Summary:</span>
                  <span className="value">Final project for DESIGN 170</span>
                </div>
              </div>
            </div>

            <div className="project-summary">
              <p>
                The ECLIPSE poster series aims to celebrate intellectuals,
                trailblazers, and innovators who are often overshadowed by
                mainstream figures. By presenting them in an engaging visual
                format, the series informs and educates while redefining what it
                means to be an icon. This project was my final project for
                DESIGN 170: Visual Frontiers, a course focused on using graphic
                design to communicate effectively across various mediums,
                including branding, typography, color, photography, and layout
                systems.
              </p>
            </div>
          </div>
        </div>

        <hr className="divider" />

        <motion.div
          className="project-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }}
          transition={{ duration: 0.8 }}
          variants={groupAnimation}
        >
          <h2>Innovators are Overshadowed in Popular Media</h2>
          <div className="innovators-container">
            <div className="innovator-image">
              {/* Embed Image */}
              <figure className="design-container">
                <img
                  src="/poster_room.jpg"
                  alt="Design Changes"
                  className="design-image"
                />
              </figure>
            </div>
            <div className="innovator-problem">
              <ul>
                <li>
                  <strong>The Problem</strong>: Posters traditionally showcase
                  musicians, celebrities, and athletes. But intellectuals and
                  innovators who shape our world often go unrecognized in
                  mainstream culture.
                </li>
                <li>
                  <strong>The Solution</strong>: Design a visually striking and
                  educational poster series, supported by a cohesive branding
                  identity and interactive experiences, to celebrate these
                  individuals.
                </li>
              </ul>
            </div>
          </div>
          <hr className="divider" />
        </motion.div>

        <motion.div
          className="project-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.03 }}
          transition={{ duration: 0.8 }}
          variants={groupAnimation}
        >
          <h2>Crafting the ECLIPSE Brand</h2>
          <div className="branding-images-container">
            {/* List of branding images */}
            {[
              { src: "/brand_intro2.png", alt: "Brand Intro" },
              { src: "/brand_logo.png", alt: "Logo" },
              { src: "/brand_variation.png", alt: "Logo Variations" },
              { src: "/brand_type.png", alt: "Typography" },
              { src: "/brand_color.png", alt: "Color Style" },
              { src: "/brand_photo.png", alt: "Photography" },
            ].map((image, index) => (
              <motion.div
                key={index}
                className="branding-image-item"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="branding-image"
                />
              </motion.div>
            ))}
          </div>
          <hr className="divider" />
        </motion.div>

        <motion.div
          className="project-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.05 }}
          transition={{ duration: 0.8 }}
          variants={groupAnimation}
        >
          <h2>Posters</h2>
          <div className="poster-images-container">
            {/* List of branding images */}
            {[
              { src: "/Marie.png", alt: "Marie Curie" },
              { src: "/mission.png", alt: "Brand Mission" },
              { src: "/space.png", alt: "Valentina Tereshkova" },
              { src: "/starter2.png", alt: "Starter Collection" },
            ].map((image, index) => (
              <motion.div
                key={index}
                className="poster-image-item"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
              >
                <img src={image.src} alt={image.alt} className="poster-image" />
                <p className="poster-caption">{image.alt}</p>
              </motion.div>
            ))}
          </div>
          <hr className="divider" />
        </motion.div>

        <motion.div
          className="project-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.15 }}
          transition={{ duration: 0.8 }}
          variants={groupAnimation}
        >
          <h2>Showcasing the Project in an Interactive Prototype</h2>
          <p>
            Below is a Figma prototype that highlights the project's mission and
            showcases the ECLIPSE poster series. This interactive experience
            allows users to explore the project's vision, understand its
            educational purpose, and view the posters in a dynamic and visually
            captivating format.
          </p>

          {/* Embed Video */}
          <div className="video-container">
            <video width="100%" controls>
              <source src="/neweclipse.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <hr className="divider" />
        </motion.div>

        <motion.div
          className="project-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.1 }}
          transition={{ duration: 0.8 }}
          variants={groupAnimation}
        >
          <h2>Designing Homepages Like Posters</h2>
          <div className="homepage-container">
            <div className="simple-look-summary3">
              <p>
                I designed the homepages as posters, inspired by the project's
                purpose itself. Using strong typography, striking visuals, and
                minimal layouts, the homepage reflects the essence of the
                ECLIPSE series while serving as a standalone highlight.
              </p>
            </div>
            <div className="homepage-images-container">
              {/* Homepage design images */}
              {[
                { src: "/home_desktop.png", alt: "Desktop Homepage" },
                { src: "/home_mobile.png", alt: "Mobile Homepage" },
              ].map((image, index) => (
                <motion.div
                  key={index}
                  className="homepage-image-item"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.2 }}
                >
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="homepage-image"
                  />
                  <p className="homepage-caption">{image.alt}</p>
                </motion.div>
              ))}
            </div>
          </div>
          <hr className="divider" />
        </motion.div>

        <motion.div
          className="project-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.2 }}
          transition={{ duration: 0.8 }}
          variants={groupAnimation}
        >
          <h2>Reflection</h2>
          <div className="eclipse-reflection-container">
            <div className="eclipse-reflection-content">
              <h3>Lessons Learned</h3>
              <p>
                This project marked several firsts for me—it was my first
                graphic design project and my introduction to using Figma and
                Illustrator. Through the process, I gained a solid understanding
                of branding principles, from creating cohesive style guides to
                designing visual identities that align with a project's mission.
                These experiences deepened my appreciation for the intersection
                of design, storytelling, and functionality.
              </p>
            </div>
            <div className="eclipse-reflection-content">
              <h3>Future Directions</h3>
              <p>
                Looking ahead, I plan to expand the ECLIPSE series by creating
                more posters to highlight a broader range of intellectuals and
                innovators. Additionally, I aim to explore physical mediums by
                printing the posters and bringing them to life in tangible
                formats. Finally, I intend to develop the website for the
                series, transforming it from a prototype into a fully functional
                platform that celebrates these unsung heroes.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
      <ProjectNavigator currentProject="ECLIPSE" />

      <Footer />
    </div>
  );
}

export default EclipsePage;
