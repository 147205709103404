// src/CaltrainCaseStudyPage.js
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import PageNavigator from "./components/PageNavigator";
import BackButton from "./components/BackButtonBlack";
import Footer from "./components/Footer";
import ProjectNavigator from "./components/ProjectNavigator";
import "./CaltrainCaseStudyPage.css";

function CaltrainCaseStudyPage() {
  const groupAnimation = {
    hidden: { opacity: 0, y: 0, filter: "blur(10px)" },
    visible: { opacity: 1, y: 0, filter: "blur(0px)" },
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="caltrain-page">
      {!isMobile && <PageNavigator />}
      {isMobile && <BackButton />}
      <h1 className="vn-title">CalExplore</h1>
      <div className="vn-image-container">
        <img src="/calexplore_main.png" alt="VN RADAR" className="vn-image" />
      </div>

      <div className="cal-content">
        <div className="project-overview-container">
          <h2>Project Overview</h2>
          <div className="project-overview-content">
            <div className="project-info-container">
              <div className="project-info">
                <div className="project-detail">
                  <span className="label">Duration:</span>
                  <span className="value">6 weeks</span>
                </div>
                <div className="project-detail">
                  <span className="label">Skills:</span>
                  <span className="value">
                    Figma, User Research, Prototyping
                  </span>
                </div>
                <div className="project-detail">
                  <span className="label">Role:</span>
                  <span className="value">Product Designer</span>
                </div>
                <div className="project-detail">
                  <span className="label">Team:</span>
                  <span className="value">4 Product Designers</span>
                </div>
                <div className="project-detail">
                  <span className="label">Summary:</span>
                  <span className="value">
                    Caltrain Design Consulting Project
                  </span>
                </div>
              </div>
            </div>

            <div className="project-summary">
              <p>
              As part of DESIGN 121: Human-Centered Design with David Kelley, our team collaborated with Caltrain on a 6-week client project to improve the rider experience. Applying human-centered design principles, we developed the Explore Feature, a swipe-based tool integrated into the Caltrain mobile app. This feature helps riders discover curated local businesses, restaurants, and attractions near their destination. It reduces cognitive load and enhances engagement by allowing users to save, share, and navigate recommendations, transforming the app into a gateway for local exploration and community connection.
              </p>
            </div>
          </div>
        </div>
        <hr className="divider" />
        {/* Animated Sections */}
        <motion.div
          className="vn-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.17 }}
          transition={{ duration: 0.5 }}
          variants={groupAnimation}
        >
          <h2>Explore Feature Demo</h2>

          {/* Embed Video */}
          <div className="video-container">
            <video width="100%" controls>
              <source src="/cal_vid.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <hr className="divider" />
        </motion.div>

        {/* Animated Sections */}
        <motion.div
          className="vn-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.12 }}
          transition={{ duration: 0.5 }}
          variants={groupAnimation}
        >
          <h2>Discovering an Opportunity for Local Exploration</h2>

          <div className="needfinding-container">
            {/* Embed Image */}
            <figure className="graph-container">
              <img
                src="/users.jpeg"
                alt="Needfinding"
                className="graph-image"
              />
            </figure>
            <div className="simple-look-summary">
              <p>
                During need-finding, we conducted 15 interviews and field visits
                to understand Caltrain users' needs, challenges, and behaviors,
                identifying three key areas for improvement to guide our design.
              </p>

              <ul>
                <li>
                  <strong>Information Accessibility</strong>: Riders struggled
                  with timely updates on schedules, delays, and platform
                  changes, causing stress and disorganized travel
                </li>
                <li>
                  <strong>Enhanced Comfort</strong>: Commuters desired ergonomic
                  seating, productivity spaces, and refreshment options for a
                  more pleasant journey
                </li>
                <li>
                  <strong>Opportunities for Local Exploration</strong>: Riders
                  wanted better guidance on attractions, dining, and activities
                  near their destinations
                </li>
              </ul>
              <p>
                Through need-finding, we identified{" "}
                <strong>local exploration</strong> as the key opportunity to
                pursue for this project. We framed our challenge in the "How
                Might We" statement below.
              </p>
            </div>
          </div>

          <div className="starting-point-problem">
            <p>
              "How might we create an experience that helps Caltrain riders
              discover and engage with local businesses and attractions, making
              their journey more meaningful and connected to the community?"
            </p>
          </div>
          <hr className="divider" />
        </motion.div>

        {/* Animated Sections */}
        <motion.div
          className="vn-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.15 }}
          transition={{ duration: 0.5 }}
          variants={groupAnimation}
        >
          <h2>User Personas</h2>
          <div className="simple-look-summary">
            <p>
              We developed user personas, "Adventurous Lucy" and "Business Owner
              Ben," to represent key users: Lucy seeks curated local
              experiences, while Ben aims to connect with commuters and boost
              visibility.
            </p>
          </div>
          <div className="persona-class">
            {/* Embed Image */}
            <figure className="persona-container">
              <img
                src="/lucy.png"
                alt="Curious Callie Persona"
                className="persona-image"
              />
            </figure>
            <figure className="persona-container">
              <img
                src="/ben.png"
                alt="Curious Callie Persona"
                className="persona-image"
              />
            </figure>
          </div>
          <hr className="divider" />
        </motion.div>

        {/* Animated Sections */}
        <motion.div
          className="vn-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }}
          transition={{ duration: 0.5 }}
          variants={groupAnimation}
        >
          <h2>Three Initial Concepts</h2>
          <p>
            After brainstorming over 50 solutions, we narrowed our ideas into
            three core concepts.
          </p>
          <div className="initial-images-container">
            {/* List of branding images */}
            {[
              { src: "/CalAhead.png", alt: "CalAhead" },
              { src: "/calexplore_prot.png", alt: "Explore" },
              { src: "/delivery.png", alt: "Locker" },
            ].map((image, index) => (
              <motion.div
                key={index}
                className="initial-image-item"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="branding-image"
                />
              </motion.div>
            ))}
          </div>
          <hr className="divider" />
        </motion.div>

        {/* Animated Sections */}
        <motion.div
          className="vn-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.2 }}
          transition={{ duration: 0.5 }}
          variants={groupAnimation}
        >
          <h2>Users Liked Two Ideas</h2>
          <div className="users-container">
            <div className="users-summary">
              <p>
                To validate our three concepts, we tested them with 10 potential
                users, including frequent Caltrain riders and occasional
                explorers.
              </p>
            </div>
            <div className="users-points">
              <ul>
                <li>
                  <strong>Explore Feature</strong> resonated most with users due
                  to its potential to enhance their travel experience by making
                  it more engaging and adventurous.
                </li>
                <li>
                  <strong>Delivery Locker System</strong> was also intriguing
                  but raised concerns about practical implementation,
                  particularly around installation logistics and ongoing
                  maintenance.
                </li>
              </ul>
            </div>
          </div>

          <h3>Feasibility Analysis</h3>
          <div className="feasibility-change-container">
            {/* Embed Image */}
            <figure className="feasibility-container">
              <img
                src="/feasibility.png"
                alt="Design Changes"
                className="design-image"
              />
            </figure>
            <div className="design-change-text">
              <p>
                After analyzing the feasibility, we decided to move forward with
                the <strong>Explore Feature</strong> due to its scalability,
                high usability, and alignment with both commuter and business
                needs, while recognizing the logistical challenges associated
                with the locker system.
              </p>
            </div>
          </div>
          <hr className="divider" />
        </motion.div>

        {/* Animated Sections */}
        <motion.div
          className="vn-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.1 }}
          transition={{ duration: 0.5 }}
          variants={groupAnimation}
        >
          <h2>Taking Inspiration from Tinder to Increase Engagement</h2>
          <div className="design-change-container">
            <div className="design-change-text1">
              <p>
                As the lead UI/UX designer, I introduced a Tinder-like swipe
                interaction to replace the map-based view, addressing user
                feedback on choice paralysis. This approach let users focus on
                one recommendation at a time, with easy swipe gestures to save
                or dismiss.
              </p>
            </div>

            {/* Embed Image */}
            <figure className="design-container">
              <img
                src="/design_changw.png"
                alt="Design Changes"
                className="design-image"
              />
            </figure>
          </div>
          <div className="design-change-container2">
            {/* Embed Video */}
            <div className="design_video-container">
              <video width="100%" controls>
                <source src="/interaction.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="design-change-text">
              <h3>Key Benefits of the Swipe Interaction:</h3>
              <ul>
                <li>
                  <strong>Reduced Choice Paralysis</strong>: Users felt less
                  overwhelmed compared to traditional map or list-based
                  interfaces.
                </li>
                <li>
                  <strong>Quick Decision-Making</strong>: The streamlined design
                  encouraged fast engagement, ideal for commuters with limited
                  time.
                </li>
                <li>
                  <strong>Differentiation from Competitors</strong>: This
                  interaction stood out from existing platforms like Yelp and
                  Google Maps, offering a unique and engaging user experience.
                </li>
              </ul>
            </div>
          </div>
          <hr className="divider" />
        </motion.div>

        {/* Animated Sections */}
        <motion.div
          className="vn-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }}
          transition={{ duration: 0.5 }}
          variants={groupAnimation}
        >
          <h2>Final Product</h2>

          {/* Embed Image */}
          <figure className="graph-container">
            <img
              src="/final_explore.png"
              alt="Final Product"
              className="graph-image"
            />
          </figure>
          <hr className="divider" />
        </motion.div>

        {/* Animated Sections */}
        <motion.div
          className="vn-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }}
          transition={{ duration: 0.5 }}
          variants={groupAnimation}
        >
          <h2>Outcomes and Learnings</h2>
          <div className="persona-class">
            <figure className="persona-container">
              <img
                src="/deli.png"
                alt="Deliverables"
                className="persona-image"
              />
            </figure>
            <figure className="persona-container">
              <img
                src="/learning.png"
                alt="Deliverables"
                className="persona-image"
              />
            </figure>
          </div>
        </motion.div>
      </div>
      <ProjectNavigator currentProject="CalExplore" />
      <Footer />
    </div>
  );
}

export default CaltrainCaseStudyPage;
