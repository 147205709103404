// src/components/ProjectNavigator.js
import React from "react";
import { Link } from "react-router-dom";
import "./ProjectNavigator.css";

const ProjectNavigator = ({ currentProject }) => {
  // Define all projects with their paths and names
  const projects = [
    { name: "The Actor to Actor Game", path: "/works/actortoactor", image:  "/a2a_cover.png" },
    { name: "CalExplore", path: "/works/calexplore", image: "/calexplore_main.png"},
    { name: "ECLIPSE", path: "/works/eclipse", image: "/poster_mockup3.png" },
    { name: "Hostile Architecture and Inaccessibility", path: "/works/hostile-architecture", image: "/pwr.png" },
    { name: "VALUENEX", path: "/works/valuenex", image: "/radar2.png" },
    
   
    
    
   
  ];

  // Filter out the current project to show only the others
  const otherProjects = projects.filter((project) => project.name !== currentProject);

  return (
    <div className="project-navigator">
      <h3>Explore Other Projects</h3>
      <hr className="project-navigator-divider" /> {/* Divider below heading */}
      <div className="project-navigator-list">
        {otherProjects.map((project, index) => (
          <React.Fragment key={index}>
            <div className="project-navigator-item">
              <Link to={project.path} className="project-navigator-link">
                <img
                  src={project.image}
                  alt={project.name}
                  className="project-navigator-image"
                />
                <h4 className="project-navigator-title">{project.name}</h4>
              </Link>
            </div>
            {index < otherProjects.length - 1 && ( // Add divider between projects
              <hr className="project-navigator-divider" />
            )}
          </React.Fragment>
          
        ))}
      </div>
      <hr className="project-navigator-divider" />
    </div>
  );
};

export default ProjectNavigator;
