import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import BackButton from "./components/BackButtonBlack";
import PageNavigator from "./components/PageNavigator";
import "./MovieGamePage.css";
import Footer from "./components/Footer";
import ProjectNavigator from "./components/ProjectNavigator";

function MovieGamePage() {
  const [isMobile, setIsMobile] = useState(false);

  // Check if the screen width is mobile-sized on component mount
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Animation variant for desktop, non-animated display for mobile
  const groupAnimation = isMobile
    ? {
        hidden: { opacity: 1, y: 0, filter: "blur(0px)" },
        visible: { opacity: 1, y: 0, filter: "blur(0px)" },
      }
    : {
        hidden: { opacity: 0, y: 0, filter: "blur(10px)" },
        visible: { opacity: 1, y: 0, filter: "blur(0px)" },
      };

  return (
    <div className="movie-game-page">
      {!isMobile && <PageNavigator />}
      {isMobile && <BackButton />}

      <h1 className="movie-game-title">The Actor to Actor Game</h1>
      <div className="movie-image-container">
        
        <img
          src="/a2a_page_img.png"
          alt="Actor to Actor game"
          className="movie-image"
        />
      </div>

      <div className="movie-game-content">
        {/* New Parent Container */}

        <div className="project-overview-container">
          <h2>Project Overview</h2>
          <div className="project-overview-content">
            <div className="project-info-container">
              <div className="project-info">
                <div className="project-detail">
                  <span className="label">Year:</span>
                  <span className="value">June 2024 - October 2024</span>
                </div>
                <div className="project-detail">
                  <span className="label">Tools:</span>
                  <span className="value">Figma, Python, React</span>
                </div>
                <div className="project-detail">
                  <span className="label">Role:</span>
                  <span className="value">
                    UI/UX Designer, Full-stack Developer
                  </span>
                </div>
                <div className="project-detail">
                  <span className="label">Summary:</span>
                  <span className="value">Internship Project</span>
                </div>
              </div>
            </div>

            <div className="project-summary">
              <p>
                The Actor to Actor Game is a web-app game inspired by the "Six
                Degrees of Kevin Bacon" theory, challenging users to connect
                actors through their co-star networks. Developed during an
                internship with VALUENEX, a leader in predictive analytics and
                intellectual property analysis, the game gamifies data analysis
                to showcase VALUENEX's advanced Network Analysis capabilities.
                This project highlights the company's innovative approach by
                transforming complex data analytics into an engaging, gamified
                experience.
              </p>
            </div>
          </div>
        </div>
        <hr className="divider" />
        {/* Animated Sections */}
        {/* Conditionally render motion.div or div based on screen size */}
        {isMobile ? (
          <div className="movie-game-description">
            <h2>Gameplay Demo</h2>

            {/* Embed Video */}
            <div className="video-container">
              <video width="100%" controls>
                <source src="/a2av3.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <hr className="divider" />
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.075 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>Gameplay Demo</h2>

            {/* Embed Video */}
            <div className="video-container">
              <video width="100%" controls>
                <source src="/a2av3.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <hr className="divider" />
          </motion.div>
        )}

        {/* Animated Sections */}
        {/* Conditionally render motion.div or div based on screen size */}
        {isMobile ? (
          <div className="movie-game-description">
            <h2>Turning a Complex Graph into an Interactive Game</h2>
            <div className="starting-point-container">
              {/* Embed Image */}
              <div className="starting-point-graph">
                <figure className="graph-container">
                  <img
                    src="/starting_point.png"
                    alt="Starting Point Graph"
                    className="graph-image"
                  />
                </figure>
              </div>
              <div className="starting-point-text">
                <p>
                  The graph represents a network of 1,000 movies with over 5,000
                  interconnected nodes representing actors and movies. Nodes
                  connect through shared movies and co-stars, enabling
                  exploration of relationships and collaborations. My role was
                  to transform this graph into an interactive game.
                </p>
              </div>
            </div>
            <div className="starting-point-problem">
              <p>
                The challenge was to simply and gamify the graph-based network
                of actors and movies, showcasing the network analysis in an
                intuitive and interactive format
              </p>
            </div>
            <hr className="divider" />
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.2 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>Turning a Complex Graph into an Interactive Game</h2>
            <div className="starting-parent">
              <div className="starting-point-container">
                {/* Embed Image */}
                <div className="starting-point-graph">
                  <figure className="graph-container">
                    <img
                      src="/starting_point.png"
                      alt="Starting Point Graph"
                      className="graph-image"
                    />
                  </figure>
                </div>
                <div className="starting-point-text">
                  <p>
                    The graph represents a network of 1,000 movies with over
                    5,000 interconnected nodes representing actors and movies.
                    Nodes connect through shared movies and co-stars, enabling
                    exploration of relationships and collaborations. My role was
                    to transform this graph into an interactive game.
                  </p>
                </div>
              </div>
              <div className="starting-point-problem">
                <p>
                  The challenge was to simply and gamify the graph-based network
                  of actors and movies, showcasing the network analysis in an
                  intuitive and interactive format
                </p>
              </div>
            </div>
            <hr className="divider" />
          </motion.div>
        )}

        {/* Repeat the above pattern for other sections */}
        {isMobile ? (
          <div className="movie-game-description">
            <h2>An Early Design Crossroad</h2>
            <div className="early-design-explanation">
              <p>
                During the brainstorming phase, I explored two main ideas to
                structure the gameplay experience.
              </p>
            </div>
            <div className="early-design-container">
              <div className="early-design-graph-text">
                <div className="early-design-text">
                  <h3>1. Dynamic Node Graph Display</h3>
                  <p>
                    This approach would visually display the actor connection
                    nodes in real-time during gameplay, allowing players to see
                    the web of connections as they navigated through the game.
                  </p>
                </div>

                <figure className="graph-container">
                  <img
                    src="/Network_version.png"
                    alt="Dynamic Node Graph Display"
                    className="graph-image"
                  />
                  <figcaption className="image-caption">
                    Prototype of Dynamic Node Graph Display
                  </figcaption>
                </figure>
              </div>

              <div className="early-design-graph-text">
                <div className="early-design-text">
                  <h3>2. Simplified Gameplay Screen</h3>
                  <p>
                    A minimalist approach, focusing solely on user input and
                    immediate feedback, without the added complexity of showing
                    the underlying graph during gameplay.
                  </p>
                </div>

                <figure className="graph-container">
                  <img
                    src="/Simple_version.png"
                    alt="Simplified Gameplay Screen"
                    className="graph-image"
                  />
                  <figcaption className="image-caption">
                    Prototype of Simplified Gameplay Screen
                  </figcaption>
                </figure>
              </div>
            </div>
            <hr className="divider" />
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.03 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>An Early Design Crossroad</h2>
            <div className="early-design-explanation">
              <p>
                During the brainstorming phase, I explored two main ideas to
                structure the gameplay experience.
              </p>
            </div>
            <div className="early-design-container">
              <div className="early-design-graph-text">
                <div className="early-design-text">
                  <h3>1. Dynamic Node Graph Display</h3>
                  <p>
                    This approach would visually display the actor connection
                    nodes in real-time during gameplay, allowing players to see
                    the web of connections as they navigated through the game.
                  </p>
                </div>

                <figure className="graph-container">
                  <img
                    src="/Network_version.png"
                    alt="Dynamic Node Graph Display"
                    className="graph-image"
                  />
                  <figcaption className="image-caption">
                    Prototype of Dynamic Node Graph Display
                  </figcaption>
                </figure>
              </div>

              <div className="early-design-graph-text">
                <div className="early-design-text">
                  <h3>2. Simplified Gameplay Screen</h3>
                  <p>
                    A minimalist approach, focusing solely on user input and
                    immediate feedback, without the added complexity of showing
                    the underlying graph during gameplay.
                  </p>
                </div>

                <figure className="graph-container">
                  <img
                    src="/Simple_version.png"
                    alt="Simplified Gameplay Screen"
                    className="graph-image"
                  />
                  <figcaption className="image-caption">
                    Prototype of Simplified Gameplay Screen
                  </figcaption>
                </figure>
              </div>
            </div>
            <hr className="divider" />
          </motion.div>
        )}

        {isMobile ? (
          <div className="movie-game-description">
            <h2>Users Preferred Simplicity</h2>
            <div className="users-container">
              <div className="users-summary">
                <p>
                  To determine the most user-friendly approach, I conducted
                  interviews with potential players. These interviews provided
                  valuable insights:
                </p>
              </div>
              <div className="users-points">
                <ul>
                  <li>
                    <strong>Preference for Simplicity:</strong> Players favored
                    a clean, distraction-free interface to focus on solving
                    actor connections
                  </li>
                  <li>
                    <strong> Cognitive Load:</strong> Testing showed that
                    displaying the dynamic graph added unnecessary complexity,
                    reducing enjoyment, especially for users unfamiliar with
                    graph systems
                  </li>
                </ul>
              </div>
            </div>
            <hr className="divider" />
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.15 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>Users Preferred Simplicity</h2>
            <div className="users-container">
              <div className="users-summary">
                <p>
                  To determine the most user-friendly approach, I conducted
                  interviews with potential players. These interviews provided
                  valuable insights:
                </p>
              </div>
              <div className="users-points">
                <ul>
                  <li>
                    <strong>Preference for Simplicity:</strong> Players favored
                    a clean, distraction-free interface to focus on solving
                    actor connections
                  </li>
                  <li>
                    <strong> Cognitive Load:</strong> Testing showed that
                    displaying the dynamic graph added unnecessary complexity,
                    reducing enjoyment, especially for users unfamiliar with
                    graph systems
                  </li>
                </ul>
              </div>
            </div>
            <hr className="divider" />
          </motion.div>
        )}

        {isMobile ? (
          <div className="movie-game-description">
            <h2>The Simplified Look</h2>
            <div className="simple-look-container">
              <div className="simple-look-summary">
                <p>
                  Based on these findings, I prioritized simplicity with a
                  minimalist interface for inputting guesses and receiving
                  feedback, avoiding graph visualizations.
                </p>
              </div>
            </div>
            <figure className="simplified-image">
              <img
                src="/simplified_look.png"
                alt="Dynamic Node Graph Display"
                className="simplified-image"
              />
            </figure>
            <hr className="divider" />
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.15 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>The Simplified Look</h2>
            <div className="simple-look-container">
              <div className="simple-look-summary">
                <p>
                  Based on these findings, I prioritized simplicity with a
                  minimalist interface for inputting guesses and receiving
                  feedback, avoiding graph visualizations.
                </p>
              </div>
            </div>
            <figure className="simplified-image">
              <img
                src="/simplified_look.png"
                alt="Dynamic Node Graph Display"
                className="simplified-image"
              />
            </figure>
            <hr className="divider" />
          </motion.div>
        )}

        {isMobile ? (
          <div className="movie-game-description">
            <h2>A New Direction for Graph Visualization</h2>
            <div className="simple-look-container">
              <div className="simple-look-summary">
                <p>
                  At the client's request, I created a dedicated page with an
                  interactive node graph, allowing users to visually explore
                  actor connections at their own pace without disrupting the
                  core gameplay experience.
                </p>
              </div>
            </div>
            <figure className="simplified-image">
              <img
                src="/graph_page1.png"
                alt="Graph Display"
                className="simplified-image"
              />
            </figure>
            <figure className="simplified-image">
              <img
                src="/graph_pagev2.png"
                alt="Graph Display"
                className="simplified-image"
              />
            </figure>
            <figure className="simplified-image">
              <img
                src="/graph_page3.png"
                alt="Graph Display"
                className="simplified-image"
              />
            </figure>
            <hr className="divider" />
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.07 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>A New Direction for Graph Visualization</h2>
            <div className="simple-look-container">
              <div className="simple-look-summary">
                <p>
                  At the client's request, I created a dedicated page with an
                  interactive node graph, allowing users to visually explore
                  actor connections at their own pace without disrupting the
                  core gameplay experience.
                </p>
              </div>
            </div>
            <figure className="simplified-image">
              <img
                src="/graph_page1.png"
                alt="Graph Display"
                className="simplified-image"
              />
            </figure>
            <figure className="simplified-image">
              <img
                src="/graph_pagev2.png"
                alt="Graph Display"
                className="simplified-image"
              />
            </figure>
            <figure className="simplified-image">
              <img
                src="/graph_page3.png"
                alt="Graph Display"
                className="simplified-image"
              />
            </figure>
            <hr className="divider" />
          </motion.div>
        )}

        {isMobile ? (
          <div className="movie-game-description">
            <h2>Development</h2>
            <div className="development-container">
              <div className="development-section">
                <h3>Backend</h3>
                <ul>
                  <li>
                    Built a Python and Flask backend to handle complex game
                    logic and state management
                  </li>
                  <li>
                    Preprocessed actor-movie data using Python's NetworkX
                    library, utilizing Dijkstra's algorithm for dynamic
                    pathfinding
                  </li>
                </ul>
              </div>
              <div className="development-section">
                <h3>Frontend</h3>
                <ul>
                  <li>
                    Developed the React-based frontend to ensure a seamless user
                    experience with an emphasis on interaction design
                  </li>
                  <li>
                    Integrated responsive design principles to ensure
                    cross-device compatibility
                  </li>
                </ul>
              </div>
            </div>
            <div className="dev-images">
              <figure className="dev-image">
                <img
                  src="/dev_mobile.png"
                  alt="Graph Display"
                  className="dev-image"
                />
              </figure>
              <figure className="dev-image">
                <img
                  src="/dev_desktop.png"
                  alt="Graph Display"
                  className="dev-image"
                />
              </figure>
            </div>
            <hr className="divider" />
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.15 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>Development</h2>
            <div className="development-container">
              <div className="development-section">
                <h3>Backend</h3>
                <ul>
                  <li>
                    Built a Python and Flask backend to handle complex game
                    logic and state management
                  </li>
                  <li>
                    Preprocessed actor-movie data using Python's NetworkX
                    library, utilizing Dijkstra's algorithm for dynamic
                    pathfinding
                  </li>
                </ul>
              </div>
              <div className="development-section">
                <h3>Frontend</h3>
                <ul>
                  <li>
                    Developed the React-based frontend to ensure a seamless user
                    experience with an emphasis on interaction design
                  </li>
                  <li>
                    Integrated responsive design principles to ensure
                    cross-device compatibility
                  </li>
                </ul>
              </div>
            </div>
            <div className="dev-images">
              <figure className="dev-image">
                <img
                  src="/dev_mobile.png"
                  alt="Graph Display"
                  className="dev-image"
                />
              </figure>
              <figure className="dev-image">
                <img
                  src="/dev_desktop.png"
                  alt="Graph Display"
                  className="dev-image"
                />
              </figure>
            </div>
            <hr className="divider" />
          </motion.div>
        )}

        {isMobile ? (
          <div className="movie-game-description">
            <h2>Outcomes & Learnings</h2>
            <div className="grid-container">
              <div className="grid-item">
                <h3>User Engagement</h3>
                <p>
                  Successfully balanced game difficulty through dynamic path
                  recalculations and a hint system, enhancing retention and user
                  engagement
                </p>
              </div>
              <div className="grid-item">
                <h3>Skill Development</h3>
                <p>
                  Deepened expertise in full-stack development, API integration,
                  and algorithm design, while honing my UI/UX capabilities for
                  creating intuitive and visually appealing interfaces
                </p>
              </div>
              <div className="grid-item">
                <h3>Design Insights</h3>
                <p>
                  The iterative feedback-driven process improved both usability
                  and visual appeal, which are critical for user-centered design
                </p>
              </div>
              <div className="grid-item">
                <h3>Future Plans</h3>
                <p>
                  VALUENEX plans to launch the game in early 2025 to showcase
                  its innovative network analysis capabilities
                </p>
              </div>
            </div>
            <hr className="divider" />
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>Outcomes & Learnings</h2>
            <div className="grid-container">
              <div className="grid-item">
                <h3>User Engagement</h3>
                <p>
                  Successfully balanced game difficulty through dynamic path
                  recalculations and a hint system, enhancing retention and user
                  engagement
                </p>
              </div>
              <div className="grid-item">
                <h3>Skill Development</h3>
                <p>
                  Deepened expertise in full-stack development, API integration,
                  and algorithm design, while honing my UI/UX capabilities for
                  creating intuitive and visually appealing interfaces
                </p>
              </div>
              <div className="grid-item">
                <h3>Design Insights</h3>
                <p>
                  The iterative feedback-driven process improved both usability
                  and visual appeal, which are critical for user-centered design
                </p>
              </div>
              <div className="grid-item">
                <h3>Future Plans</h3>
                <p>
                  VALUENEX plans to launch the game in early 2025 to showcase
                  its innovative network analysis capabilities
                </p>
              </div>
            </div>
            {/*
            <a
              href="https://actortoactor.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="play-button2">Play the Game</button>
            </a>
            */}
            <hr className="divider" />
          </motion.div>
        )}
      </div>
      <ProjectNavigator currentProject="The Actor to Actor Game" />
      <Footer />
    </div>
  );
}

export default MovieGamePage;
